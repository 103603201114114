<script setup lang="ts">
import type { Locale } from 'lc-services/types'

type MenuLink = {
  active?: boolean
  dataCy: string
  name: string
  link: string | { name: string; params: { name: string } }
  icon: IconPath
  unapprovedContract?: boolean
}

withDefaults(
  defineProps<{
    isBannerDisplayed?: boolean
  }>(),
  {
    isBannerDisplayed: false,
  },
)

const { t, locale } = useI18n<unknown, Locale>()
const config = useRuntimeConfig()
const localePath = useLocalePath()
const route = useRoute()
const { isDesktopOrTablet } = useBreakpoint()
const { isOwner, user } = useUser()
const { userIsPartner, logout } = useAuth()
const { properties } = useUserHouses()
const { unapprovedContractInformation } = useUserContracts()

const menuCustomer = computed<MenuLink[]>(() => [
  {
    dataCy: 'myaccount-bookings',
    name: userIsPartner.value ? t('account.bookings') : t('account.myTrips'),
    link: 'myaccount-bookings',
    icon: 'calendar',
  },
  {
    dataCy: 'myaccount-destinationAlert',
    name: t('account.destinationAlert'),
    link: 'myaccount-destinationAlert',
    icon: 'bell',
  },
  {
    dataCy: 'myaccount-profile',
    name: t('account.profile'),
    link: 'myaccount-profile',
    icon: 'profileCircle',
  },
])
const menuOwner = computed(() =>
  (properties.value ?? [])
    .map<MenuLink>((property) => ({
      active: property.slug[locale.value] === route?.params?.name,
      dataCy: 'myaccount-property-name-rentals',
      name: property.name,
      link: {
        name: 'myaccount-property-name-rentals',
        params: { name: property.slug[locale.value] },
      },
      icon: 'home',
      unapprovedContract: unapprovedContractInformation.value
        .map((houseContract) => Number(houseContract.house))
        .includes(Number(property.id)),
    }))
    .concat({
      dataCy: 'myaccount-documents',
      name: t('account.documents'),
      link: 'myaccount-documents',
      icon: 'page',
    }),
)
const userEmail = computed(() => user.value.email)
const partnershipAgencyName = computed(() => user.value.partnershipAgencyName)
const typeformFeedbackID = computed(
  () =>
    ({
      fr: config.public.typeformFeedbackOwnerFR,
      en: config.public.typeformFeedbackOwnerEN,
    })[locale.value],
)
</script>

<template>
  <div>
    <div class="mb-8 md:py-6">
      <div class="my-8 mb-6 md:mt-2">
        <h2 class="m-0 text-2xl">
          {{ $t('account.myAccount') }}
        </h2>
        <p
          v-if="partnershipAgencyName"
          class="mb-0 mt-4 font-bold text-secondary-400"
        >
          {{ partnershipAgencyName }}
        </p>
        <span class="text-sm">{{ user.email }}</span>
      </div>

      <div
        v-if="isOwner"
        class="mb-7 tablet:mb-6 tablet:border-b tablet:border-gray-200 tablet:pb-5"
      >
        <h3
          class="mb-4 font-sansSerif text-base font-normal uppercase tracking-widest"
        >
          {{ $t('account.ownerAccount') }}
        </h3>

        <nav>
          <ul class="m-0">
            <NuxtLink
              v-for="tab in menuOwner"
              v-slot="{ href, navigate, isActive }"
              :key="tab.name"
              :to="localePath(tab.link)"
              custom
            >
              <li class="border-b border-gray-200 tablet:border-0">
                <a
                  :class="[
                    { 'font-medium': isActive || tab.active },
                    'block py-4 text-gray-700 hover:text-gray-700 hover:no-underline tablet:py-2.5 tablet:text-gray-600 tablet:hover:text-gray-600',
                  ]"
                  :data-v-inspector="$attrs['data-v-inspector']"
                  :href="href"
                  :data-cy="tab.dataCy"
                  @click="navigate"
                >
                  <span
                    :class="[
                      { 'tablet:border-gray-700': isActive || tab.active },
                      { 'tablet:border-transparent': !isActive && !tab.active },
                      'flex items-center justify-between tablet:border-l-[3px]',
                    ]"
                  >
                    <BaseIcon
                      :stroke-width="isActive || tab.active ? 1.3 : 1"
                      class="mr-3 flex-none text-gray-700 tablet:ml-4"
                      :name="tab.icon"
                    />
                    <span class="grow">
                      {{ tab.name }}
                      <span
                        v-if="tab.unapprovedContract"
                        class="account-menu__section-bullet"
                      />
                    </span>
                    <span class="flex-none tablet:hidden">
                      <BaseIcon name="navArrowRight" />
                    </span>
                  </span>
                </a>
              </li>
            </NuxtLink>
          </ul>
        </nav>
      </div>

      <div class="mb-6 tablet:border-b tablet:border-gray-200 tablet:pb-5">
        <h3
          class="mb-4 font-sansSerif text-base font-normal uppercase tracking-widest"
        >
          <span v-if="partnershipAgencyName">
            {{ $t('account.agencyAccount') }}
          </span>
          <span v-else>{{ $t('account.clientAccount') }}</span>
        </h3>

        <nav>
          <ul class="m-0">
            <NuxtLink
              v-for="tab in menuCustomer"
              v-slot="{ href, navigate, isActive }"
              :key="tab.name"
              :to="localePath(`${tab.link}`)"
              custom
            >
              <li class="border-b border-gray-200 tablet:border-0">
                <a
                  :class="[
                    { 'font-medium': isActive || tab.active },
                    'block py-4 text-gray-700 hover:text-gray-700 hover:no-underline tablet:py-2.5 tablet:text-gray-600 tablet:hover:text-gray-600',
                  ]"
                  :data-cy="tab.dataCy"
                  :data-v-inspector="$attrs['data-v-inspector']"
                  :href="href"
                  @click="navigate"
                >
                  <span
                    :class="[
                      { 'border-gray-700': isActive || tab.active },
                      { 'border-transparent': !isActive && !tab.active },
                      'flex items-center border-l-[3px]',
                    ]"
                  >
                    <BaseIcon
                      :name="tab.icon"
                      :stroke-width="isActive || tab.active ? 1.3 : 1"
                      class="mr-3 flex-none text-gray-700 tablet:ml-4"
                    />
                    <span class="grow">{{ tab.name }}</span>
                    <span class="flex-none tablet:hidden">
                      <BaseIcon name="navArrowRight" />
                    </span>
                  </span>
                </a>
              </li>
            </NuxtLink>
          </ul>
        </nav>
      </div>

      <div
        v-if="!isOwner && !partnershipAgencyName"
        class="mb-6 border-b border-gray-200 pb-5"
      >
        <h3
          class="mb-4 font-sansSerif text-base font-normal uppercase tracking-widest"
        >
          {{ $t('account.ownerAccount') }}
        </h3>

        <i18n-t
          class="mb-0 text-base"
          keypath="account.listingYourPropertyForRent"
          tag="p"
        >
          <template #link>
            <BaseButton
              :to="localePath('owners')"
              class="text-base"
              color="black"
              data-testid="account-menu-link-owner"
              variant="link"
            >
              {{ $t('account.joinOurCollection') }}
            </BaseButton>
          </template>
        </i18n-t>
      </div>

      <div
        v-if="isOwner"
        class="mb-6 flex cursor-pointer py-2 pl-1 text-gray-700"
        :data-tf-slider="typeformFeedbackID"
        data-tf-position="right"
        data-tf-opacity="100"
        data-tf-medium="snippet"
        :data-tf-hidden="`email=${userEmail}`"
        role="button"
      >
        <BaseIcon class="mr-2.5 tablet:ml-4" name="messageText" />
        <span class="grow">{{ $t('account.shareMyFeedback') }}</span>
        <span class="tablet:hidden">
          <BaseIcon name="navArrowRight" />
        </span>
      </div>

      <BaseButton
        class="cursor-pointer text-md underline"
        variant="link"
        color="black-underline"
        font-weight="font-normal"
        data-cy="logout"
        @click="logout({ redirect: true })"
      >
        {{ $t('account.logout') }}
      </BaseButton>

      <div class="my-9 flex items-center rounded-sm border border-gray-200 p-4">
        <BaseIcon class="mr-3" name="mail" />

        <i18n-t class="mb-0 text-md" keypath="account.needHelp" tag="p">
          <template #link>
            <BaseButton
              :to="localePath('contact')"
              class="text-md"
              color="secondary"
              data-testid="account-menu-link-contact"
              variant="link"
            >
              {{ $t('global.contactUs') }}
            </BaseButton>
          </template>
        </i18n-t>
      </div>
      <div v-if="isBannerDisplayed" class="rounded-md bg-beige p-4">
        <div class="flex gap-2">
          <div>
            <p v-if="isOwner" class="leading-2 text-xs">
              {{ $t('account.downloadAppDesktopOwner') }}
            </p>
            <div v-else class="leading-2 text-xs">
              <h5 class="font-sansSerif text-md font-bold">
                {{ $t('account.downloadAppTitle') }}
              </h5>
              <p>{{ $t('account.downloadApp') }}</p>
            </div>
          </div>
          <img
            v-if="isDesktopOrTablet"
            alt=""
            width="71"
            height="71"
            sizes="142px"
            src="~/assets/svg/QRCode.svg"
          />
        </div>
        <div v-if="!isDesktopOrTablet" class="flex flex-col">
          <TheAppStoreLinks
            :size-image="{ width: 112, height: 36 }"
            class="flex-1 items-start lg:items-end"
            source="client_account_banner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.account-menu__section-bullet {
  border-radius: 50px;
  @apply bg-error align-top inline-block h-2 w-2;
}
</style>
